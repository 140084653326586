import { Invitation } from './../model/i-invitation';

import { UserProfile } from './../model/i-user-profile';
import { Injectable } from '@angular/core';
import { AngularFireAuth} from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { GooglePlus} from '@ionic-native/google-plus/ngx';
import { LoadingService } from './loading.service';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { collectionData } from 'rxfire/firestore';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  db = firebase.firestore();
  userRef = this.db.collection('userProfile');

  userProfile:UserProfile;
  userProfile$ : Observable<any>
  private readonly LAST_URL_KEY = 'workplace';
  constructor(
    private route: ActivatedRoute,
    private platform: Platform,
    public afAuth: AngularFireAuth,
    private gplus: GooglePlus,
    public loadingService: LoadingService,
    public router: Router
  ) { 

  }

  googleLogin():Promise<any>{ 
    let returnUrl = this.route.snapshot.queryParamMap.get('returnUrl') || '/dashboard';
    
    localStorage.setItem('returnUrl', returnUrl);
    localStorage.setItem('awaitRedirect', "true");
    if(this.platform.is('cordova')){
      return this.nativeGoogleLogin();
    }else{
      let googleAuthProvider = new firebase.auth.GoogleAuthProvider();
      googleAuthProvider.setCustomParameters({
        prompt : 'select_account'
      })
      // return this.afAuth.signInWithRedirect(new firebase.auth.GoogleAuthProvider());
      return this.afAuth.signInWithPopup(googleAuthProvider);
      // return this.afAuth.signInWithRedirect(googleAuthProvider);
    }
  }

  nativeGoogleLogin():Promise<any>{
    console.log("NATIVE GOOGLE LOGIN");
    return this.gplus.login(
      {
      'webClientId': environment.webClientId,
      'offline': true,
      'scopes': 'profile email'
    })
    .then(
      (glogin) => {
        this.afAuth
          .signInAndRetrieveDataWithCredential(firebase.auth.GoogleAuthProvider.credential(glogin.idToken))
        this.loadingService.loadingRedirect();
      },
      (reason)=>{
        console.log("Google Login Failed, reason : " + reason);
      }
    );
  }

  getUserProfile(uid:string):Promise<firebase.firestore.DocumentSnapshot>{
    return this.db.doc(`/userProfile/${uid}`).get();
  }

  getCurrentProfile(uid : string) : Observable<any>{
    let user = this.userRef.where(firebase.firestore.FieldPath.documentId(), '==', uid);
    return this.userProfile$ = collectionData(user, 'uid').pipe(
      map(user=>{
        return user;
      })
    )
  }

  removeInvitation(uid: string, invitation : Invitation){
    return this.userRef.doc(`${uid}`).update({
      listInvitation : firebase.firestore.FieldValue.arrayRemove({
        uid : invitation.uid,
        displayName : invitation.displayName,
        photoURL : invitation.photoURL,
        workplaceUID : invitation.workplaceUID,
        workplaceName : invitation.workplaceName
      })
    })
  }

  createAccount(userProfile: UserProfile):Promise<void>{
    return this.db.doc(`/userProfile/${userProfile.uid}`).set({
      displayName: userProfile.displayName,
      phoneNumber: userProfile.phoneNumber,
      joinDate: firebase.firestore.FieldValue.serverTimestamp(),
      photoURL: userProfile.photoURL,
      email: userProfile.email
    });
  }

  logout(){
    this.afAuth.signOut().then(()=>{
      sessionStorage.removeItem(this.LAST_URL_KEY);
      this.router.navigate(['/']);
    });  
  }

  
}
