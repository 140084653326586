import { WorkplaceService } from './service/workplace.service';
import { LoadingService } from './service/loading.service';
import { AuthService } from './service/auth.service';
import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Workplace } from './model/iworkplace';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  private readonly LAST_URL_KEY = 'lastUrl';

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public auth: AuthService,
    private router: Router,
    public loadingService: LoadingService, 
    public workplaceService: WorkplaceService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      const statePage = localStorage.getItem('awaitRedirect');
      if (statePage === "true") {
        this.loadingService.loadingRedirect();
      }
      if (!this.workplaceService.currentWorkplace) {
        const workplaceData = localStorage.getItem('workplace');
        const myWorkplace: Workplace = workplaceData ? JSON.parse(workplaceData) : null;
        this.workplaceService.setCurrentWorkplace(myWorkplace);
      }
    });
  }

  ngOnInit() {
    this.auth.afAuth.user.subscribe(observer => {
      localStorage.setItem('awaitRedirect', "false");
      if (this.loadingService.isLoading) {
        this.loadingService.dismiss();
      }
      if (observer) {
        this.auth.userProfile = observer;
        localStorage.setItem('uid', this.auth.userProfile.uid);
        this.auth.getUserProfile(observer.uid).then(doc => {
          if (!doc.exists) {
            // Create new user
            this.auth.createAccount(this.auth.userProfile);
          }
        });

        // Listen to navigation events and save the URL
        this.router.events
          .pipe(filter(event => event instanceof NavigationEnd))
          .subscribe((event: NavigationEnd) => {
            sessionStorage.setItem(this.LAST_URL_KEY, event.urlAfterRedirects);
          });

        // Navigate to the last URL if available
        const lastUrl = sessionStorage.getItem(this.LAST_URL_KEY);
        if (lastUrl && lastUrl !== "/") {
          this.router.navigateByUrl(lastUrl);
        } else {
          this.router.navigate(['workplace']);
        }
      }
    });
  }
}