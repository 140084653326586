import { UserProfile } from './../model/i-user-profile';
import { AuthService } from './auth.service';
import { Workplace } from './../model/iworkplace';
import { Injectable } from '@angular/core';
// import * as firebase from 'firebase/app';
// import 'firebase/firestore';

import {firestore} from 'firebase/app';
import {collectionData} from 'rxfire/firestore';
import {map} from 'rxjs/operators'
import { combineLatest, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WorkplaceService {

  db = firestore();
  workplaceRef = this.db.collection("workplace");
  listWorkplace$ : Observable<any>;
  currentWorkplace : Workplace;
  workplaceInvitation$ : Observable<any>;

  constructor(
    public authService: AuthService
  ) { }

  saveWorkplace(workplace: Workplace):Promise<firebase.firestore.DocumentReference<firebase.firestore.DocumentData>>{
    return this.workplaceRef.add(workplace);
  }

  getWorkplaceByID(workplaceUID : string):Observable<any>{
    let workplaceInvitation = this.workplaceRef.where(firestore.FieldPath.documentId(),'==', workplaceUID);
    return this.workplaceInvitation$ = collectionData(workplaceInvitation, 'uid').pipe(
      map(workplace=>{
        return workplace;
      })
    )
  }

  getWorkplaceList():Observable<any>{
    let userUID = "";
    userUID = this.authService.userProfile.uid;
    let myWorkplace = this.workplaceRef.where("ownerUID","==", userUID);
    let myWorkplacePartner = this.workplaceRef.where("listRekan", "array-contains",userUID);
    let myWorkplace$ = collectionData(myWorkplace,'uid')
    .pipe(
      map(listWorkplace=>{
        return listWorkplace;
      })
    )

    let myWorkplacePartner$ = collectionData(myWorkplacePartner,'uid')
    .pipe(
      map(listWorkplace=>{
        return listWorkplace;
      })
    )

    return this.listWorkplace$ = combineLatest([myWorkplace$,myWorkplacePartner$]).pipe(
      map((workplace)=>{
        let [listWorkplace, listWorkplacePartner] = workplace;
        return [...listWorkplace, ...listWorkplacePartner];
      })
    )
  }

  setCurrentWorkplace(workplace: Workplace){
    this.currentWorkplace = workplace;
  }

  getCurrentWorkplace(){
    return this.currentWorkplace;
  }

  updateWorkplace(workplace : Workplace): Promise<void>{
    const increment = firestore.FieldValue.increment(1);
    return this.workplaceRef.doc(`${workplace.uid}`).update({
      noRM : increment
    })
  }

  sendInvitation(workplace : Workplace, staff : UserProfile): Promise<void>{
    return this.workplaceRef.doc(`${workplace.uid}`).update({
      listRekanDetail : firestore.FieldValue.arrayUnion({
        uid : staff.uid,
        displayName : staff.displayName,
        email : staff.email,
        photoURL : staff.photoURL,
        status : 'awaitApproval'
      }),
      listPendingRekan : firestore.FieldValue.arrayUnion(staff.uid)
    })
  }

  removeStaff(workplace : Workplace, staff : UserProfile){
    console.log("Remove Staff : " + staff.status);
    return this.workplaceRef.doc(`${workplace.uid}`).update({
      listRekanDetail : firestore.FieldValue.arrayRemove({
        uid : staff.uid,
        displayName : staff.displayName,
        email : staff.email,
        photoURL : staff.photoURL,
        status : staff.status
      }),
      listRekan : firestore.FieldValue.arrayRemove(staff.uid)
    })
  } 

  deleteStaffRequest(workplaceUID: string, staff : UserProfile){
    console.log("Remove Staff : " + staff.status);
    return this.workplaceRef.doc(`${workplaceUID}`).update({
      listRekanDetail : firestore.FieldValue.arrayRemove({
        uid : staff.uid,
        displayName : staff.displayName,
        email : staff.email,
        photoURL : staff.photoURL,
        status : staff.status
      })
    })
  } 

  removePendingStaff(workplaceUID : string, uid : string){
    return this.workplaceRef.doc(`${workplaceUID}`).update({
      listPendingRekan : firestore.FieldValue.arrayRemove(uid)
    })
  }

  updateRekanDetail(workplaceUID : string, workplace: Workplace, uid: string ){
    return this.workplaceRef.doc(`${workplaceUID}`).update({
      listRekanDetail : workplace.listRekanDetail,
      listPendingRekan : firestore.FieldValue.arrayRemove(uid),
      listRekan: firestore.FieldValue.arrayUnion(uid)
    })
  }

}
