// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase : {
    apiKey: "AIzaSyBW8qfF7YiNO8RaQFZUqk2UMfbGBzyVIdE",
    authDomain: "medical-a7f5d.firebaseapp.com",
    databaseURL: "https://medical-a7f5d.firebaseio.com",
    projectId: "medical-a7f5d",
    storageBucket: "medical-a7f5d.appspot.com",
    messagingSenderId: "70221312212",
    appId: "1:70221312212:web:b16baa103dab4ee21ecb41",
    measurementId: "G-1ZZCSWLMGL"

  },
  webClientId: "70221312212-9qcpngkgfeue2n3202g7b0ma4d7vtfor.apps.googleusercontent.com",
  API_KEY_FOR_BROWSER_RELEASE: 'AIzaSyBD6W7zri7VJpHdXAkG1Wg-qWsyGb4vt7E',
  API_KEY_FOR_BROWSER_DEBUG: 'AIzaSyBD6W7zri7VJpHdXAkG1Wg-qWsyGb4vt7E'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
