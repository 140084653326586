import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  isLoading = false;

  constructor(
    private loadingController: LoadingController
  ) {     
    
  }

  async loadingRedirect(){
    this.isLoading = true;
    return this.loadingController.create({})
      .then(a =>{
        a.present().then(()=>{
          if(!this.isLoading){
            a.dismiss();
          }
        })
      });
  }

  async dismiss(){
    this.isLoading = false;
    return await this.loadingController.dismiss();
  }

  async presentMessage(msg:string){
    this.isLoading = true;
    return this.loadingController.create({message: msg})
      .then(a =>{
        a.present().then(()=>{
          if(!this.isLoading){
            a.dismiss();
          }
        })
      });
  }

  async presentTimerMessage(msg:string){
    const loading = await this.loadingController.create({
      message : msg,
      duration : 1000,
      spinner : null
    });
    await loading.present();
  }

}
