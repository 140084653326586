import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'workplace',
    loadChildren: () => import('./workplace/workplace.module').then( m => m.WorkplacePageModule)
  },
  {
    path: 'user-profile',
    loadChildren: () => import('./user-profile/user-profile.module').then( m => m.UserProfilePageModule)
  },
  {
    path: 'patient',
    loadChildren: () => import('./patient/patient.module').then( m => m.PatientPageModule)
  },
  {
    path: 'register-patient',
    loadChildren: () => import('./register-patient/register-patient.module').then( m => m.RegisterPatientPageModule)
  },
  {
    path: 'view-detail-patient',
    loadChildren: () => import('./view-detail-patient/view-detail-patient.module').then( m => m.ViewDetailPatientPageModule)
  },
  {
    path: 'edit-patient',
    loadChildren: () => import('./edit-patient/edit-patient.module').then( m => m.EditPatientPageModule)
  },
  {
    path: 'medical-record',
    loadChildren: () => import('./medical-record/medical-record.module').then( m => m.MedicalRecordPageModule)
  },
  {
    path: 'add-medical-record',
    loadChildren: () => import('./add-medical-record/add-medical-record.module').then( m => m.AddMedicalRecordPageModule)
  },
  {
    path: 'input-icd',
    loadChildren: () => import('./input-icd/input-icd.module').then( m => m.InputICDPageModule)
  },
  {
    path: 'edit-medical-record',
    loadChildren: () => import('./edit-medical-record/edit-medical-record.module').then( m => m.EditMedicalRecordPageModule)
  },
  {
    path: 'view-all-patient',
    loadChildren: () => import('./view-all-patient/view-all-patient.module').then( m => m.ViewAllPatientPageModule)
  },
  {
    path: 'staff',
    loadChildren: () => import('./staff/staff.module').then( m => m.StaffPageModule)
  },
  {
    path: 'workplace-invitation',
    loadChildren: () => import('./workplace-invitation/workplace-invitation.module').then( m => m.WorkplaceInvitationPageModule)
  },  {
    path: 'privacy-policy',
    loadChildren: () => import('./privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyPageModule)
  },
  {
    path: 'term-of-service',
    loadChildren: () => import('./term-of-service/term-of-service.module').then( m => m.TermOfServicePageModule)
  }

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
